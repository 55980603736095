<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-top: 0;">
    <v-layout wrap>
      <v-flex md12>
        <material-card
          :title="$t('common.search')"
          color="green"
          flat
          full-width
          class="bg-white"
        >
          <v-form>
            <v-container py-0>
              <v-layout wrap>
                <v-flex
                  xs12
                  md2
                >
                  <v-menu
                    ref="menu"
                    v-model="menuStartDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="startDateFormatted"
                        :label="$t('evoucher.issuance.fromDate')"
                        persistent-hint
                        @blur="date = parseDate(startDateFormatted)"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="startDate"
                      :min="minStartDate"
                      no-title
                      @input="menuStartDate = false"/>
                  </v-menu>
                </v-flex>
                <v-flex
                  xs12
                  md2>
                  <v-menu
                    ref="menu"
                    v-model="menuEndDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="endDateFormatted"
                        :label="$t('evoucher.issuance.toDate')"
                        persistent-hint
                        @blur="date = parseDate(endDateFormatted)"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="endDate"
                      :min="minEndDate"
                      no-title
                      @input="menuEndDate = false"/>
                  </v-menu>
                </v-flex>
                <v-flex
                  xs12
                  md4>
                  <v-text-field
                    v-model="search.serial"
                    :label="$t('evoucher.voucherSerial')"
                  />
                </v-flex>
                <v-flex
                  xs12
                  sm4
                  md4>
                  <v-select
                    v-model="voucherReconciliationType"
                    :items="voucherReconciliationTypeList"
                    :label="$t('voucherUsed.reconciliation')"
                  >
                    <template
                      slot="selection"
                      slot-scope="data">
                      <!-- HTML that describe how select should render selected items -->
                      {{ $t(data.item.text) }}
                    </template>
                    <template
                      slot="item"
                      slot-scope="data">
                      <!-- HTML that describe how select should render items when the select is open -->
                      {{ $t(data.item.text) }}
                    </template>
                  </v-select>
                </v-flex>
                <!-- <v-flex
                  xs12
                  sm4
                  md4>
                  <v-select
                    v-model="supplierId"
                    :items="supplierList"
                    :label="$t('Nhà cung cấp')"
                  >
                    <template
                      slot="selection"
                      slot-scope="data">
                      {{ $t(data.item.text) }}
                    </template>
                    <template
                      slot="item"
                      slot-scope="data">
                      {{ $t(data.item.text) }}
                    </template>
                  </v-select>
                </v-flex> -->
              </v-layout>
              <v-btn
                style="margin-right: 0;"
                class="pull-right"
                color="success"
                @click="getVoucherUsages()"
              >
                <span class="btn-label mr-3">
                  <i class="glyphicon glyphicon-search" />
                </span>
                <span style="text-transform: none;">{{
                  $t("common.search")
                }}</span>
              </v-btn>
            </v-container>
          </v-form>
        </material-card>
        <!-- Receive list -->
        <material-card
          :title="$t('voucherUsed.list')"
          color="green"
          flat
          full-width
        >
          <template>
            <!-- Create check voucher -->
            <v-btn
              v-if="isUsageSite()"
              :loading="btnCheckVoucherLoading"
              color="success"
              dark
              style="float: right;"
              @click="onShowModalCreateVoucherReconciliation"
            >
              <v-icon class="mr-2">mdi-transfer</v-icon>
              <span style="text-transform: none;">{{
                $t("voucherReconciliation.action.create")
              }}</span>
            </v-btn>
          </template>
          <v-data-table
            :headers="voucherInfoHeaders"
            :items="voucherInfoList"
            :no-data-text="$t('common.noDataAvailable')"
            :single-select="true"
            v-model="selected"
            hide-actions
            select-all
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="text--darken-3 font-medium"
                v-text="$t(header.text)"
              />
            </template>
            <template
              v-slot:items="props"
            >
              <tr
                :class="props.item.isLoading ? 'bg-1' : (props.selected ? 'blue--text' : '')"
                style="position: relative;">
                <td>
                  <v-checkbox
                    v-model="props.selected"
                    primary
                    hide-details />
                </td>
                <td
                  v-for="(header, index) in voucherInfoHeaders"
                  :key="header.value + index">
                  <span v-if="header.value == 'price'">
                    {{ formatMoney(props.item[header.value]) }}
                  </span>
                  <span v-else-if="header.value == 'usedPrice'">
                    {{ formatMoney(props.item[header.value]) }}
                  </span>
                  <span v-else-if="header.value == 'orderCode'">
                    <span v-if="props.item[header.value]">{{ props.item[header.value] }}</span>
                    <action-item
                      v-else
                      first
                      class="blue--text text-underline"
                      @click="onAddOrderCode($event, props.item)">
                      <u>&lt; {{ $t('user.add') }} &gt;</u>
                    </action-item>
                  </span>
                  <span v-else-if="header.value == 'reconciliationStatus'">
                    {{ $t(getReconciliationStatusText(props.item[header.value])) }}
                  </span>
                  <span v-else>{{ props.item[header.value] }}</span>
                </td>
              </tr>
            </template>
          </v-data-table>
        </material-card>
        <pagination
          :total="paginate.totalPage"
          :current-page="paginate.currentPage"
          :row-per-page="paginate.rowPerPage"
          @onPageChange="onPageChange"
        />
      </v-flex>
    </v-layout>
    <loading-bar :is-loading="isLoading" />
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
    <confirm-modal
      ref="confirmModal"
      :title="confirmModalTitle"
      @onConfirm="onConfirmCreateVoucherReconciliation"
    />
    <confirm-with-text-modal
      ref="addOrderCodeModal"
      :title="confirmModalTitle"
      :input-title="$t('evoucher.referenceCode')"
      prepend-icon="mdi-tooltip-edit"
      @onConfirm="onConfirmAddOrderCode"
    />
    <!-- <edit-reference-info-modal
      ref="editRefenceInfoModal"
      @onConfirm="onConfirmUpdateReferenceInfo"/> -->
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import ConfirmModal from 'Components/ConfirmModal'
import dateUtils from 'utils/dateUtils'
import ToastType from 'enum/toastType'
import functionUtils from 'utils/functionUtils'
import moment from 'moment'
import stringUtils from 'utils/stringUtils'
import EntityType from 'enum/entityType'
// import VoucherReconciliationStatus from 'enum/voucherReconciliationStatus'
import VoucherReconciliationType from 'enum/voucherReconciliationType'
import ActionItem from 'Components/ActionItem.vue'
import ConfirmWithTextModal from 'Components/ConfirmWithTextModal'
// import EditReferenceInfoModal from './../../EditReferenceInfoModal.vue'
export default {
  components: {
    ActionItem,
    ConfirmWithTextModal,
    // EditReferenceInfoModal,
    ConfirmModal
  },
  data: () => ({
    voucherInfoHeaders: [
      { text: 'common.nonumber', value: 'stt' },
      { text: 'voucherUsed.orderCode', value: 'orderCode' },
      { text: 'voucherUsed.voucherSerial', value: 'serial' },
      { text: 'voucherUsed.voucherName', value: 'name' },
      { text: 'voucherUsed.price', value: 'price' },
      { text: 'voucherUsed.supplier', value: 'supplier' },
      { text: 'voucherUsed.customerUsed', value: 'usedTime' },
      // { text: 'Địa điểm sử dụng', value: 'usedSite' },
      { text: 'voucherUsed.priceUsed', value: 'usedPrice' },
      { text: 'voucherUsed.reconciliation', value: 'reconciliationStatus' }
    ],
    voucherInfoList: [],
    paginate: {
      totalPage: 1,
      currentPage: 1,
      rowPerPage: 1
    },
    isLoading: false,
    selected: [],
    selectedItem: null,
    confirmModalTitle: '',
    btnCheckVoucherLoading: false,
    search: {
      serial: null
    },
    startDate: null,
    startDateFormatted: null,
    menuStartDate: false,
    minStartDate: null,
    endDate: null,
    endDateFormatted: null,
    menuEndDate: false,
    minEndDate: null,
    voucherReconciliationType: VoucherReconciliationType.NOT_SUBMIT_YET,
    voucherReconciliationTypeList: stringUtils.VoucherReconciliationTypeList,
    supplierId: null,
    supplierList: []
  }),
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR',
      'VOUCHERS_USED_DATA'
    ])
  },
  watch: {
    startDate (val) {
      this.startDateFormatted = this.formatDate(this.startDate)
      if (this.startDate >= this.endDate) {
        this.endDate = moment(this.startDate, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD')
      }
      this.minEndDate = moment(this.startDate, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD')
    },
    endDate (val) {
      this.endDateFormatted = this.formatDate(this.endDate)
    },
    VOUCHERS_USED_DATA () {
      let res = this.VOUCHERS_USED_DATA
      // Handle paginate
      this.paginate.currentPage = res.paginate.currentPage
      this.paginate.totalPage = res.paginate.totalPage
      this.paginate.rowPerPage = res.paginate.perPage / 2
      // Handle response data
      let voucherData = res.results
      this.voucherInfoList = []
      for (let i = 0, size = voucherData.length; i < size; i++) {
        let obj = {
          stt: i + 1 + (res.paginate.currentPage - 1) * res.paginate.pageSize,
          id: voucherData[i].usage_info.id,
          orderCode: voucherData[i].usage_info.code,
          serial: voucherData[i].voucher_info.serial,
          name: voucherData[i].voucher_info.name,
          price: voucherData[i].voucher_info.price,
          supplier: voucherData[i].voucher_info.supplier,
          usedTime: dateUtils.formatBeautyDate(voucherData[i].usage_info.used_date),
          usedSite: voucherData[i].usage_info.used_site,
          usedPrice: (voucherData[i].usage_info.price && (voucherData[i].usage_info.price > 0)) ? voucherData[i].usage_info.price : voucherData[i].voucher_info.price,
          reconciliationStatus: voucherData[i].usage_info.reconciliation_status,
          check: ''
        }
        this.voucherInfoList.push(obj)
      }
    }
  },
  created () {
    this.getVoucherUsages()
  },
  methods: {
    /**
     * Check is site
     */
    isUsageSite: function () {
      return functionUtils.isEntityType(EntityType.SITE)
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null
      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    /**
     * Format money
     */
    formatMoney: function (price) {
      return functionUtils.convertFormatNumberToMoney(price)
    },
    /**
     * Confirm create voucher check
     */
    onConfirmCreateVoucherReconciliation: function () {
      let voucherUsageIds = []
      for (let i = 0, size = this.selected.length; i < size; i++) {
        voucherUsageIds.push(this.selected[i].id)
      }
      let filter = {
        voucherUsageIds: voucherUsageIds
      }
      this.btnCheckVoucherLoading = true
      this.CREATE_VOUCHERS_RECONCILIATION(filter).then(
        function () {
          this.btnCheckVoucherLoading = false
          this.ON_SHOW_TOAST({
            message: this.$t('common.success'),
            styleType: ToastType.SUCCESS
          })
          this.getVoucherUsages()
        }.bind(this)
      ).catch(
        function (error) {
          if (functionUtils.isNull(error.response)) {
            this.ON_SHOW_TOAST({
              message: this.$t('login.somethingWentWrong'),
              styleType: ToastType.ERROR
            })
          } else if (!functionUtils.isNull(error.response.data.errorText)) {
            let errorText = error.response.data.errorText
            this.ON_SHOW_TOAST({
              message: this.$t(`${errorText}`),
              styleType: ToastType.ERROR
            })
          } else {
            this.ON_SHOW_TOAST({
              message: this.$t('login.somethingWentWrong'),
              styleType: ToastType.ERROR
            })
          }
          this.btnCheckVoucherLoading = false
        }.bind(this)
      )
    },
    /**
     * Create voucher check
     */
    onShowModalCreateVoucherReconciliation: function () {
      if (this.selected.length > 0) {
        this.confirmModalTitle = this.$t('voucherReconciliation.modal.confirmCreate')
        this.$refs.confirmModal.onShowModal()
      } else {
        this.ON_SHOW_TOAST({
          message: this.$t('voucherReconciliation.plsSelectVoucherNeedToCheck'),
          styleType: ToastType.ERROR
        })
      }
    },
    /**
     * Page change
     */
    onPageChange: function (page) {
      this.paginate.currentPage = page
      this.getVoucherUsages()
    },
    /**
     * Get vouchers usage
     */
    getVoucherUsages: function () {
      let filter = {
        params: {
          page: this.paginate.currentPage,
          serial: this.search.serial,
          reconciliationType: this.VoucherReconciliationType,
          startDate: this.startDate,
          endDate: this.endDate
        }
      }
      this.isLoading = true
      this.GET_VOUCHERS_USED(filter).then(
        function () {
          this.isLoading = false
        }.bind(this)
      )
    },
    getReconciliationStatusText: function (value) {
      return functionUtils.getReconciliationStatusText(value)
    },
    onAddOrderCode: function (event, usageItem) {
      this.selectedItem = usageItem
      this.confirmModalTitle = this.$t('voucherReconciliation.modal.confirmCreate')
      this.$refs.addOrderCodeModal.onShowModal()
    },
    onConfirmAddOrderCode: function (orderCode) {
      this.isLoading = false
      // orderCode = orderCode.toUpperCase().trim()
      let filter = {
        usageId: this.selectedItem.id,
        orderCode: orderCode
      }
      this.UPDATE_VOUCHER_USAGE_ORDER_CODE(filter).then(
        function () {
          this.ON_SHOW_TOAST({
            message: this.$t('common.success'),
            styleType: ToastType.SUCCESS
          })
          this.isLoading = false
          // this.getVoucherUsages()
          this.selectedItem.orderCode = orderCode
        }.bind(this)
      ).catch(
        function (error) {
          this.isLoading = false
          if (!functionUtils.isNull(error.response.data.errorText)) {
            let errorText = error.response.data.errorText
            this.ON_SHOW_TOAST({
              message: this.$t(`${errorText}`),
              styletype: ToastType.ERROR
            })
          } else {
            this.ON_SHOW_TOAST({
              message: this.$t('login.somethingWentWrong'),
              styleType: ToastType.ERROR
            })
          }
        }.bind(this)
      )
    },
    ...mapActions([
      'GET_VOUCHERS_USED',
      'CREATE_VOUCHERS_RECONCILIATION',
      'UPDATE_VOUCHER_USAGE_ORDER_CODE',
      'ON_SHOW_TOAST'
    ])
  }
}
</script>

<style>

</style>
